import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { compareWithSomething } from '../../../../_core/state/global/global.utils';
import { LocaleDto } from "../../../../../../../api/src/creative-unit-package/models/locale.dto";

@Component({
	selector: 'app-select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectInputComponent extends BaseInputComponent implements OnInit, OnChanges {
	@Input() italicPlaceholder: string | null;
	@Output() openedChange = new EventEmitter<boolean>();

	@Input() showLocale: boolean = false;
	@Input() localeData: LocaleDto;
	@Output() localeChange = new EventEmitter<string>();

	public compareWithSomething = compareWithSomething;

	constructor() {
		super();
	}

	getLocale(value: string, localeData: any): string {
		let locale = window.navigator.language;
		if (localStorage.getItem('localeCode')) {
			locale = localStorage.getItem('localeCode');
		}

		// See if the localeData has a value for the current locale.
		if (localeData && localeData[locale]) {
			return localeData[locale];
		} else {
			return value;
		}
	}

}
