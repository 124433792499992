import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetLocaleDialogComponent } from '../../../locale/set-locale-dialog/set-locale-dialog.component';
import { LocaleDto } from '../../../../../../../api/src/creative-unit-package/models/locale.dto';

@Component({
  selector: 'app-set-locale-edit-button',
  templateUrl: './set-locale-edit-button.component.html',
  styleUrl: './set-locale-edit-button.component.scss'
})
export class SetLocaleEditButtonComponent implements OnChanges {
	@Input() localeData: LocaleDto;

	@Input() selection: any[] | null = null;

	@Output() localeChange = new EventEmitter<LocaleDto>();

	public activeLocalizations: boolean = false;

	constructor(
		public readonly dialog: MatDialog
	) {}

	ngOnChanges() {
		this.activeLocalizations = this.localeData && Object.keys(this.localeData).filter(l => !!l).length > 0;
	}

	async open() {
		let dialogRef = this.dialog.open(SetLocaleDialogComponent, {
			data: {
				localeData: this.localeData,
				selection: this.selection
			},
			disableClose: true
		});

		let result = await dialogRef.afterClosed().toPromise();
		if (result) {
			// this.localeData = {};
			this.localeChange.emit(result);
		}
	}
}
