import axios from 'axios';
import { Provider } from '../notification/notification.entity';
import { ProductType } from '../../product/product.entity';
import { StoryFormat } from '../../project/models/workspace-state';
import qs from "qs";

export class SettingsResponse {
	organizationName: string;
	organizationSlug: string;
	createdAt: string;
	updatedAt: string;
	assetProvider: string;
	defaultClipPath: string;
	defaultLocale: string;
	defaultMusicPath: string;
	defaultVoiceoverPath: string;
	siteTitle: string;
	siteFaviconUrl: string;
	customStyles?: string;
	enabled: boolean;
	aiVoiceEnabled?: boolean;
	projectApprovalRequired?: boolean;
	emailReviewersOnFinalRender?: boolean;
	organizationLogo?: {
		data: {
			attributes: {
				url: string;
			};
		};
	};
	loginPageImage?: {
		data: {
			attributes: {
				url: string;
			};
		};
	};
	signupPageImage?: {
		data: {
			attributes: {
				url: string;
			};
		};
	};
	defaultProductArray: any[];
	defaultSubscriptionArray: any[];
	disclaimer?: string;
	terms?: string;
	freeTrialEnabled?: boolean;
	freeTrialDuration?: string;
	transcriptDownloadEnabled?: boolean;
	reviewProcessEnabled?: boolean;
	previewShareLinksEnabled?: boolean;
	projectStateExportEnabled?: boolean;
	disableCheckout?: boolean;
	downloadReceiptEnabled: boolean;
	paymentMethods: any;
	products: { data: StrapiProduct[] };
	emailName: string;
	emailAddress: string;
	emailService?: string;
	paymentMethodsRequired: boolean;
	bodyScripts: string;
	signupExtraPrivacyConditions: boolean;
	metadata?: any;
	legacyHeroBannerEnabled: boolean;
	primaryColor: string;
	migrateToForeverSubscriptions: boolean;
	legacyOrderByProvinceEnabled: boolean;
	exportsRequireApproval: boolean;
	disableReceiptButton: boolean;
	disableTermsPopup: boolean;
	enableNewCreativePackageRequests: boolean;
	creativePackageRequestFormId: string;
	hideSignUpButton: boolean;
	hideBusinessAddButton: boolean;
	enableCustomExportFileNames: boolean;
	enableCreativePackageTerms: boolean;
	creativePackageTerms: string;
	enableExportActions: boolean;
	showDuplicateTemplateButton: boolean;
	disableExternalProjectLink: boolean;
	enforceEncodeTaskCompletion: boolean;
}

export class AuthenticationSettingsResponse {
	authenticationStrategy: 'basic';
	signUpMethod: 'allowAll';
	emailRegexRequirement?: string;
	singlePassLength: number;
	singlePassExpire: string;
}

export class NotificationResponse {
	slug: string;
	subject?: string;
	provider: Provider;
	remoteTemplateId?: string;
	templateText?: string;
	templateHtml?: string;
	locale?: string;
}

export class StrapiUserResponse {
	id: number;
	username: string;
	email: string;
	provider: string;
	confirmed: string;
	blocked: string;
	createAt: string;
	updatedAt: string;
	user_role: string;
	deactivated: boolean;
	reviewer: boolean;
	role: any;
}

export class StrapiBusinessResponse {
	id: number;
	name: string;
	website: string;
	address: string;
	phone: string;
	billingAddress: string;
	billingCity: string;
	billingState: string;
	updatedAt: string;
	createdAt: string;
	billingZip: string;
}

export class MergedStrapiResponse {
	settings: SettingsResponse;
	authenticationSettings: AuthenticationSettingsResponse;
	notifications: NotificationResponse[];
	users: StrapiUserResponse[];
}

export interface StrapiProduct {
	id: number;

	attributes: {
		title: string;
		slug: string;
		price: number;
		productPriceId: string;
		description?: string;
		userDescription?: string;
		type: ProductType;
		enabled: boolean;
		defaultProduct: boolean;
		countries: { data: StrapiCountry[] };
	};
}

export interface StrapiCountry {
	id: number;

	attributes: {
		name: string;
		alpha2Code: string;
		alpha3Code: string;
	};
}

export class Strapi {
	private static config = {
		host: process.env.STRAPI_HOST?.replace(/\/$/, ''),
		apiKey: process.env.STRAPI_API_KEY
	};

	public static async getAllSettings(): Promise<MergedStrapiResponse[]> {
		const settings = await this.getSettings().catch(err => {
			console.log(err);
			return null;
		});

		// const businesses = await this.getBusinesses().catch(err => {
		// 	console.log(err);
		// 	return null;
		// });

		const authenticationSettings = await this.getAuthenticationSettings().catch(err => {
			console.log(err);
			return null;
		});

		const notifications = await this.getNotifications().catch(err => {
			console.log(err);
			return null;
		});

		const users = await this.getUsers().catch(err => {
			console.log(err);
			return null;
		});

		// console.log('users', users);
		return [
			{
				settings,
				authenticationSettings,
				notifications,
				users
			}
		];
	}
	static getBusinesses() {
		return axios
			.get(this.config.host + '/api/businesses?populate=*', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp =>
				resp.data?.data?.map(d => {
					d.attributes.id = d.id;
					return d.attributes;
				})
			);
	}

	static getVoices() {
		return axios
			.get(this.config.host + '/api/voices?populate=*', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp =>
				resp.data?.data?.map(d => {
					d.attributes.id = d.id;
					return d.attributes;
				})
			);
	}

	public static async getSettings(): Promise<SettingsResponse> {
		const query = qs.stringify({
			populate: {
				0: {
					populate: "*"
				},
				products: {
					populate: "*"
				},
				paymentMethods: {
					populate: "*"
				},
				organizationLogo: {
					populate: "*"
				},
				signupPageImage: {
					populate: "*"
				},
				loginPageImage: {
					populate: "*"
				}
			}
		})

		return axios
			.get(
				this.config.host + '/api/organization-setting?' + query,
				{
					headers: {
						Authorization: `Bearer ${this.config.apiKey}`
					}
				}
			)
			.then(resp => resp.data?.data?.attributes)
			.catch(err => {
				console.log('err', err.response.data);
				return null;
			});
	}

	public static async getAuthenticationSettings(): Promise<AuthenticationSettingsResponse> {
		return axios
			.get(this.config.host + '/api/authentication', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data?.data?.attributes)
			.catch(err => {
				console.log('err', err.response.data);
				return null;
			});
	}

	public static async getNotifications() {
		return axios
			.get(this.config.host + '/api/notifications?locale=all', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data?.data?.map(d => d.attributes))
			.catch(err => {
				console.log('err', err.response.data);
				return null;
			});
	}

	public static async getCollections(collection: string, query: string) {
		return axios
			.get(`${this.config.host}/api/${collection}?${query}`, {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data?.data?.map(d => d.attributes))
			.catch(err => {
				console.log('err', err.response.data);
				return null;
			});
	}

	public static async getUsers() {
		return axios
			.get(this.config.host + '/api/users', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data);
	}

	public static async getClips(paramString?: string) {
		// URL encode the param string
		let params: any = '';
		if (paramString) {
			params = new URLSearchParams(paramString);
		}

		const url = this.config.host + '/api/clips' + (params ? '?' + params : '');

		console.log('URL', url);
		return axios
			.get(url, {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data);
	}

	public static async getUserByEmail(email: string): Promise<StrapiUserResponse> {
		return axios
			.get(this.config.host + '/api/users?filters[email][$eq]=' + encodeURIComponent(email.toLowerCase().trim()), {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data);
	}

	public static async updateBusiness(business: any, id: string): Promise<StrapiBusinessResponse> {
		return axios
			.put(
				this.config.host + `/api/businesses/${id}`,
				{ data: business },
				{
					headers: {
						Authorization: `Bearer ${this.config.apiKey}`
					}
				}
			)
			.then(resp => resp.data)
			.catch(err => {
				console.log('err', err.response.data);
				return null;
			});
	}

	public static async getStoryFormats(): Promise<StoryFormat[]> {
		return axios
			.get(this.config.host + '/api/story-formats?populate=*', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => {
				return resp.data?.data?.map(sf => new StoryFormat(sf.attributes));
			});
	}

	public static async getProducts(): Promise<any> {
		return axios
			.get(this.config.host + '/api/products?[populate]=*', {
				headers: {
					Authorization: `Bearer ${this.config.apiKey}`
				}
			})
			.then(resp => resp.data)
			.catch(err => {
				console.log('err', err.response.data.data);
				return null;
			});
	}

	/**
	 * Takes an array of strings and builds the filter query needed for Strapi.
	 */
	public static generateFilterString(key: string, arr: string[]) {
		let paramString = '';
		for (let i = 0; i < arr.length; i++) {
			paramString += `filters[${key}][$in][${i}]=${arr[i]}`;

			if (i < arr.length - 1) {
				paramString += '&';
			}
		}
		return paramString;
	}
}
