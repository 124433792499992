import { Locale } from '../../_core/models';
import { Field } from '../../_core/fraud-prevention/modules/forms/models';
import { DataSourceAddDto } from '../../data-source/dtos/data-source-add.dto';
import { DataSourceConnectorType, ItemFormat } from '../../data-source/models';

export enum ExportActionType {
	Email = 'email',
	// DV360 = 'dv360',
}

export class Translation {
	name: string;
	description: string;
	entityName: string;
	entityNamePlural: string;
	// Note, groups are not supported yet.
	form: Pick<Field, 'slug' | 'displayName' | 'placeholderValue'>[];
}

export type Translations = {
	[locale in Locale]?: Translation;
}

export class ExportActionConfig {

}

export class ExportActionAdminConfig {

}

export const ExportFilenameDataSourceConfig : DataSourceAddDto = {
	name: 'Export Filename DataSource',
	slug: 'export-filename',
	connectorType: DataSourceConnectorType.Native,
	config: {
		target: '',
		mapping: [
			{
				path: 'name',
				displayName: 'Name',
				format: ItemFormat.Text
			},
			{
				path: 'template',
				displayName: 'Template',
				format: ItemFormat.Text
			},
			{
				path: 'includedByDefault',
				displayName: 'Included by default',
				format: ItemFormat.Boolean

			}
		]
	}
}
