import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { PublicExportAction } from '../../../../../../../api/src/export-action/export-action.entity';
import { ExportActionService } from '../../../../_core/state/export-action/export-action.service';
import { ExportActionQuery } from '../../../../_core/state/export-action/export-action.query';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { DataSourceService } from '../../../../_core/state/data-source/data-source.service';
import { ExportActionAddDto } from '../../../../../../../api/src/export-action/dtos/export-action-add.dto';
import { Project } from '../../../../_core/state/project';
import { GlobalQuery } from '../../../../_core/state/global';
import { GlobalSettings } from '../../../../_core/state/global/global.model';
import { ExportActionType } from '../../../../../../../api/src/export-action/models';
import { FilenameConfig, FilenameFormatType } from '../../../../../../../api/src/encode/models/job-def.dto';
import { v4 as uuid } from 'uuid';
import { MergeTags } from '../../../../../../../api/src/_core/utils/utils.merge-tags';

export type SelectedExportActions = any & { enabled?: boolean };
export type SelectedExportActionEntity = any & { enabled?: boolean, added?: boolean };

@Component({
	selector: 'app-export-destinations',
	templateUrl: './export-destinations.component.html',
	styleUrls: ['./export-destinations.component.scss'],
})
export class ExportDestinationsComponent implements OnInit {
	readonly includedByDefaultSlug = 'included-by-default';
	readonly exportFilenameDataSourceSlug = 'export-filename';

	readonly EXPORT_FILENAMES_PRESETS = [
		{
			value: undefined,
			display: $localize`:Selection Option@@exportDestinationsExportNamePrefixDefault:Default`,
			template: '{{variables.project.name}}_{{variables.uuid}}',
			isCustom: false,
			isPreset: true
		},
		{
			value: FilenameFormatType.PROJECT_NAME_DATE,
			display: $localize`:Selection Option@@exportDestinationsExportNamePrefixProjectNameAndDate:Project Name and Date`,
			template: '{{variables.project.name}}_{{variables.date}}',
			isCustom: false,
			isPreset: true
		},
		{
			value: FilenameFormatType.CUSTOM,
			display: $localize`:Selection Option@@exportDestinationsExportNamePrefixCustom:Custom`,
			isCustom: true,
			isPreset: true
		}
	]

	@Input() project: Project;
	@Input() enableCustomExportFileNames: boolean = true;
	@Input() enableExportActions: boolean;
	@Output() configChange = new EventEmitter<ExportActionAddDto[]>();
	@Output() filenameConfigChange = new EventEmitter<FilenameConfig>();

	exportActions$: Observable<PublicExportAction[]>;
	siteCopy$: Observable<Partial<GlobalSettings>>;
	exportFilenames$: Observable<any>;

	form: FormGroup;
	exportActions: PublicExportAction[];

	addedExportActions: SelectedExportActions[] = [];
	addedEntitiesMap: { [key: string]: SelectedExportActionEntity[] } = {};

	constructor(
		private readonly dialog: MatDialog,
		private readonly formBuilder: FormBuilder,
		readonly exportActionService: ExportActionService,
		readonly exportActionQuery: ExportActionQuery,
		readonly dataSourceService: DataSourceService,
		readonly globalQuery: GlobalQuery
	) {
		this.exportActionService.find({enabled: true, projectId: null}).subscribe();
		this.exportFilenames$ = this.dataSourceService.query(this.exportFilenameDataSourceSlug, {}).pipe(
			map(({data}) => {

				const configuredValues = data.results.map(result => ({
					value: result.name,
					display: result.name,
					template: result.template,
					includedByDefault: result.includedByDefault,
					isCustom: false,
					isPreset: false
				}));

				if (!this.form.value?.formatType) {
					const defaultPrefix = configuredValues.find(value => !!value.includedByDefault) || this.EXPORT_FILENAMES_PRESETS[0];
					this.form.patchValue({formatType: defaultPrefix});
					this.onPrefixChange(defaultPrefix);
				}
				return [
					...this.EXPORT_FILENAMES_PRESETS,
					...configuredValues
				]
			}),
			catchError((err) => {
				//fallback to default values
				this.form.patchValue({formatType: this.EXPORT_FILENAMES_PRESETS[0]});
				this.onPrefixChange(this.EXPORT_FILENAMES_PRESETS[0]);
				return of(this.EXPORT_FILENAMES_PRESETS);
			})
		);

		this.exportActions$ = this.exportActionQuery.selectAll().pipe(
			tap(actions => {
				this.exportActions = actions;

				if (this.addedExportActions.length === 0) {
					this._initSettings();
				}
			})
		);


		this.siteCopy$ = this.globalQuery.copy$;
	}


	ngOnInit() {
		this._createForm();
	}

	async resetSettings() {

		const result = await this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: $localize`:Reset Confirm Dialog@@exportDestinationsResetSettingsTitle:Reset settings`,
				message: $localize`:Reset Confirm Dialog@@exportDestinationsResetSettingsMessage:All changes will be lost. Are you sure you want to reset settings?`,
				confirm: $localize`:Reset Confirm Dialog@@exportDestinationsResetSettingsConfirm:Reset`,
				remove: false
			},
			disableClose: false
		}).afterClosed().toPromise();

		if (result) {
			this._initSettings();
		}


	}


	onAddExportAction(exportAction: PublicExportAction) {
		const addedExportAction = {...exportAction, enabled: true};
		this.addedExportActions = [...this.addedExportActions, addedExportAction];
		this._changeConfig();
	}

	onUpdateExportAction(exportAction: PublicExportAction, {actionConfig, entityConfig}) {
		this.addedExportActions = this.addedExportActions.map(action => action.id === exportAction.id ? {...action, ...actionConfig} : action);
		this.addedEntitiesMap[exportAction.id] = entityConfig;
		this._changeConfig();
	}

	excludeAddedActions(exportAction: PublicExportAction, addExportAction: PublicExportAction[]) {
		if (!addExportAction?.length) {
			return true;
		}

		return addExportAction?.findIndex(addAction => addAction.id === exportAction.id) === -1;
	}


	exportActionIdentify(index, action: PublicExportAction) {
		return action.id;
	}

	onPrefixChange(value) {

		if (value.isCustom) {
			this.form.controls.filename.enable();
		} else {
			this.form.controls.filename.disable();
		}

		let filename = null;
		if (value.template) {
			if (value.isPreset) {
				const variables = {project: this.project, date: new Date().toLocaleDateString(), uuid: uuid()};
				filename = MergeTags.applyMergeTagsToString(value.template, {variables});
			} else {
				filename = value.template;
			}
		}

		this.form.patchValue({filename});
	}

	valueListCompare(a, b) {
		return a?.value === b?.value;
	}


	private _initSettings() {
		this.addedExportActions = [];
		this.addedEntitiesMap = {};

		this.exportActions.filter(exportAction => !!exportAction.action.config?.[this.includedByDefaultSlug]).forEach(action => {
			this.onAddExportAction(action);
		})

	}

	private _createForm() {
		this.form = this.formBuilder.group({
			formatType: [null],
			filename: [null],
		});

		this.form.valueChanges.subscribe(value => {
			if (this.form.valid) {
				let filename = undefined;
				if (value.formatType?.isPreset) {
					if (value.formatType?.isCustom) {
						filename = value.filename;
					}
				} else {
					filename = value.formatType?.template || ''
				}

				this.filenameConfigChange.emit({
					formatType: value.formatType?.value,
					filename: filename
				});
			}
		});
	}

	private _changeConfig() {
		const exportActions = this.addedExportActions.filter(action => !!action.enabled).map(action => {
			if (action.action.type === ExportActionType.Email) {


				const configWithoutPrinters = Object.keys(action.action.config).filter(key => key !== 'email-to')
					.filter(key => key !== this.includedByDefaultSlug)
					.map(key => ({slug: key, value: action.action.config[key]}))
					.filter(field => !!field.value);

				const emailTo = this.addedEntitiesMap[action.id]?.length ? this.addedEntitiesMap[action.id].filter(entity => !!entity.enabled).map(entity => entity.contacts) : action.action.config['email-to'];
				return {
					type: action.type,
					name: action.name,
					siteId: action.siteId,
					businessId: this.project.businessId,
					enabled: action.enabled,
					config: [...configWithoutPrinters, {slug: 'email-to', value: emailTo}]
				}
			}
		});

		this.configChange.emit(exportActions);
	}
}
