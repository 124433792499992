// TODO: WIP

/**
 * Local Dependencies
 */
import { GoogleMaps } from '../third-party/google-maps';
import { ValidationError } from "class-validator";

export enum Validator {
	String = 'string',
	Phone = 'phone',
	Address = 'address'
}

export class Validation {
	public static numberOrPercentRgx = /^\d+(\.\d+)?%?$/;

	// Async to support map validation
	public static async validate(input, validator: Validator, params?): Promise<boolean> {
		switch (validator) {
			case Validator.String:
				return this.validateString(input);
			case Validator.Phone:
				return this.validatePhone(input, params);
			case Validator.Address:
				return await this.validateAddress(input, params);
			default:
				return typeof input !== 'undefined';
		}
	}

	private static validateString(str) {
		if (typeof str === 'string' && str.trim().length > 0) {
			return true;
		}
		return false;
	}

	private static validatePhone(phone: string, params?) {
		if (!phone) {
			return false;
		}

		// Remove non-numbers
		phone = phone.replace(/[^0-9]+/g, '');

		// If no validators are passed,
		// default to checking US phone numbers
		if (!params || (!params.countries && !params.countries.length)) {
			params = {
				countries: ['US']
			};
		}

		for (let i = 0; i < params.countries.length; i++) {
			let country = params.countries[i];
			switch (country) {
				case 'US':
				default:
					// +1 (###) ###-#### | (###) ###-#####
					// At some point we probably want to validate area codes
					// and prefixes. Right now we just test to see if it's
					// US (+1) and 10 digits.
					if (!phone.match(/(^1[0-9]{10}$)|(^[0-9]{10}$)/)) {
						return false;
					}
					break;
			}
		}

		return true;
	}

	// Matches email based on RFC 5322
	// and, optionally a custom RegExp
	public static validateEmail(eml, params?: RegExp) {
		var emailRgx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

		if (eml && eml.match(emailRgx)) {
			if (params) {
				return eml.match(params) ? true : false;
			}
			return true;
		} else {
			return false;
		}
	}

	// Validate addresses via Google Maps Geocoding API
	private static async validateAddress(address: string, params): Promise<boolean> {
		let mapResults = await GoogleMaps.Geocoding.getAddressSuggestions(address).catch(err => {
			console.log(err);
			return false;
		});

		if (!mapResults || !mapResults[0]) {
			return false;
		}

		if (!params) {
			return true;
		}

		const components = mapResults[0].address_components;
		const keys = Object.keys(params);
		for (let i = 0; i < keys.length; i++) {
			switch (keys[i]) {
				case 'countries':
					// Check to see if the main country
					// for the address in in our required list.
					const countries = components.filter(c => c.types.indexOf('country') > -1);

					if (!countries || !countries.length) {
						return false;
					} else {
						if (params[keys[i]].indexOf(countries[0].short_name) === -1) {
							console.log('Bad country.');
							return false;
						}
					}
					break;
				case 'states':
					// Check to see if the main state
					// for the address is in our required list.
					const states = components.filter(c => c.types.indexOf('administrative_area_level_1') > -1);

					if (!states || !states.length) {
						return false;
					} else {
						if (params[keys[i]].indexOf(states[0].short_name) === -1) {
							console.log('Bad state.');
							return false;
						}
					}
					break;
			}
		}

		console.log(mapResults);

		return true;
	}

	// ValidationError has no constructor we could use to populate directly on creation
	public static populateValidationError(property: string, target?: any, constraints?: any) {
		const validationError = new ValidationError();

		validationError.property = property;

		if (target) {
			validationError.target = target;
		}
		if (constraints) {
			validationError.constraints = constraints;
		}

		return validationError;
	}
}
