import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EllipsisTooltipDirective} from "./ellipsis-tooltip.directive";
import { ProjectItemThumbnailCardHoverDirective } from "./project-item-thumbnail-card-hover.directive";
import { NumbersOnlyDirective } from "./numbers-only.directive";



@NgModule({
	declarations: [
		ProjectItemThumbnailCardHoverDirective,
		EllipsisTooltipDirective,
		NumbersOnlyDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		ProjectItemThumbnailCardHoverDirective,
		EllipsisTooltipDirective,
		NumbersOnlyDirective
	]
})
export class SharedDirectiveModule { }
