<div class="dialog-header mb-3">
	<h1 class="title mb-1" mat-dialog-title>
		<ng-container i18n="Dialog Title@@setLocaleTitle">Set Locale Strings</ng-container>
	</h1>
	<mat-icon [mat-dialog-close]="null" class="cursor-pointer">close</mat-icon>
</div>

<div mat-dialog-content>
	<app-multi-add-input
		class="block"
		[dataType]="'object'"
		[keyLabel]="'Language Code'"
		[selection]="data.selection"
		[currentValue]="data.localeData"
		[keyOptions]="locales$ | async"
		(valueChange)="setLocaleData($event)"
	></app-multi-add-input>
</div>

<div mat-dialog-actions class="dialog-footer">
	<button
		mat-flat-button
		class="create-new"
		color="primary"
		class="submit margin-right-auto"
		(click)="save()"
		i18n="Dialog Action Button@@setLocaleButtonLabel"
	>
		Save
	</button>
</div>
