import { Type } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, IsString, ValidateNested, validate, Max, IsNotEmpty, IsBoolean } from 'class-validator';
import { SortDirection } from '../../_core/utils/utils.paging';
import { FilterDef } from './filter.model';

export enum DataSourceConnectorType {
	CSV = 'csv',
	SmartSheet = 'smartsheet',
	Cloudinary = 'cloudinary',
	Native = 'native',
	Strapi = 'strapi',
	Pexels = 'pexels',
	Bria = 'bria'
	// ...
}

export enum DataSourceContext {
	ProjectLink = 'project-link',
	CustomizationLink = 'customization-link',
	ImagePickerLink = 'image-picker-link',
	ImageGenerationLink = 'image-generation-link',
	VideoPickerLink = 'video-picker-link',
	AudioPickerLink = 'audio-picker-link',
	CssStyleLink = 'css-style-link'
}

// For example...
export enum ItemFormat {
	Text = 'text',
	Number = 'number',
	Percent = 'percent',
	Currency = 'currency',
	Date = 'date',
	Time = 'time',
	DateTime = 'datetime',
	Phone = 'phone',
	Email = 'email',
	Url = 'url',
	Markdown = 'markdown',
	HTML = 'html',
	JSON = 'json',
	XML = 'xml',
	Other = 'other',
	Boolean = 'boolean',
}

export class ItemDef {
	@IsOptional()
	@IsString()
	path?: string;

	@IsOptional()
	@IsString()
	displayName?: string;

	@IsOptional()
	@IsEnum(ItemFormat)
	format?: ItemFormat;
}

export class SortDef {
	@IsOptional()
	@IsString()
	path?: string;

	@IsOptional()
	@IsString()
	displayName?: string;
}

export class QueryParams {
	@IsOptional()
	@IsString()
	search?: string;

	@IsOptional()
	// @ValidateNested()
	@Type(() => Object)
	filter?: {
		[key: string]: string | number | boolean | Date | null | undefined | any[] | object;
	};

	@IsOptional()
	@IsString()
	mediaType?: string;

	// Handy for administering data in the Native Connector.
	// Used to return the full row meta data, not just the object store.
	@IsOptional()
	@IsBoolean()
	doNotMap?: boolean;

	@IsOptional()
	@IsString()
	sort?: string;

	@IsOptional()
	sortStrategy?: SortDirection;

	@IsOptional()
	@IsNumber()
	@Max(100)
	pageSize?: number;

	@IsOptional()
	@IsNumber()
	page?: number;

	@IsOptional()
	@IsString()
	cursor?: string;
}

export class ItemResult {
	/*
		Keys should line up with the 'name' property for an ItemDef entity.
		However, additional data can be included that is not included in the ItemDef mapping array.
	*/
	[key: string]: any;
}

export class QueryResult {
	total: number;
	items: ItemResult[];
	cursor?: string;
}

export class ConnectorConfig {
	@IsOptional()
	@IsString()
	target: string;

	@IsOptional()
	@IsEnum(DataSourceContext, { each: true })
	context?: DataSourceContext[];

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ItemDef)
	mapping?: ItemDef[];

	@IsOptional()
	@ValidateNested()
	@Type(() => QueryParams)
	params?: QueryParams;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => SortDef)
	sortableFields?: SortDef[];

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => FilterDef)
	filters?: FilterDef[];

	@IsOptional()
	@IsString()
	previewPath?: string;

	@IsOptional()
	@IsString()
	accessToken?: string;

	@IsOptional()
	@IsString()
	tagPath?: string;

	type?: DataSourceConnectorType;

	@IsOptional()
	attribution?: string;

	constructor() {}
}
