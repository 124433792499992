/**
 * Safely traverse through an object with a dot notated path string
 * @param path
 * @param obj
 */
export function resolveDotNotationPath(path: any, obj: any) {
	return path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self);
}

/**
 * Get the dirty values of any form.
 * @param form
 * @returns
 */
export function getDirtyValues(form: any) {
	let dirtyValues: { [key: string]: any } = {};

	Object.keys(form.controls).forEach(key => {
		let currentControl = form.controls[key];

		if (currentControl.dirty) {
			if (currentControl.controls) dirtyValues[key] = getDirtyValues(currentControl);
			else dirtyValues[key] = currentControl.value;
		}
	});

	return dirtyValues;
}

export function unfreeze(obj: any) {
	return JSON.parse(JSON.stringify(obj));
}

export function removeObjectValues(obj: object, values = [null, undefined]) {
	if (!obj){
		return obj;
	}

	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !values.includes(v)));
}
