import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse } from '../session';
import { environment } from '../../../../environments/environment';
import { map, Observable, tap } from 'rxjs';
import { DataSourceStore } from './data-source.store';
import { QueryParams } from '../../../../../../api/src/data-source/models';
import { BaseEntityService } from '../base-entity.service';
import { DataSourceAddDto } from '../../../../../../api/src/data-source/dtos/data-source-add.dto';
import { PublicDataSource } from '../../../../../../api/src/data-source/data-source.entity';
import { DataSourceUpdateDto } from '../../../../../../api/src/data-source/dtos/data-source.update.dto';
import { catchError } from "rxjs/operators";
import { DataSourceStoreWriteDto } from '../../../../../../api/src/data-source/dtos/data-source-store-write.dto';
import { DataSourceStoreDeleteDto } from '../../../../../../api/src/data-source/dtos/data-source-store-delete.dto';
import { FileDataImportUploadObject } from '../dataImport/dataImport.service';
import { GlobalService } from '../global'

@Injectable({ providedIn: 'root' })
export class DataSourceService extends BaseEntityService {
	constructor(
		private http: HttpClient,
		private dataSourceStore: DataSourceStore,
		private readonly globalService: GlobalService
	) {
		super();
	}

	find() {
		return this.http.post<DefaultResponse<any>>(`${environment.apiUrl}/data-source/find`, {}).pipe(
			tap(response => {
				this.dataSourceStore.set(response.data?.results);
			})
		);
	}

	query(slug: string, params: QueryParams) {
		return this.http.post<DefaultResponse<any>>(`${environment.apiUrl}/data-source/${slug}/query`, {
			params
		});
	}

	get({ page = 1, limit = 10, sort }): Observable<DefaultResponse<PublicDataSource>> {
		return this.http
			.post<DefaultResponse<any>>(
				`${environment.apiUrl}/data-source/find`,
				{},
				{ params: { page, perPage: limit, sortBy: sort.sortBy, order: sort.direction } }
			)
			.pipe(
				map(response => ({
					totalResults: response.data.totalResults,
					data: response.data?.results,
					status: response.status,
					message: response.message,
					numPages: response.data.numPages,
					perPage: response.data.perPage,
					page: response.data.page
				})),
				tap(response => {
					this.dataSourceStore.set(response.data);
				})
			);
	}

	getBySlug(slug: string, setStore = true, setActive = true): Observable<PublicDataSource> {
		this.dataSourceStore.setActive(null);
		return this.http.get<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source/${slug}`).pipe(
			map(response => response.data),
			tap(dataSource => {
				if (setStore){
					this.dataSourceStore.upsert(dataSource.id, dataSource);
				}

				if (setActive){
					this.dataSourceStore.setActive(dataSource.id);
				}
			})
		);
	}

	public create(dataSourceAddDto: DataSourceAddDto): Observable<DefaultResponse<PublicDataSource>> {
		return this.http.post<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source`, dataSourceAddDto).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
					this.dataSourceStore.upsert(res.data.id, res.data);
				}
			})
		);
	}
	public update(dataSourceSlug: string, dataSourceUpdateDto: DataSourceUpdateDto): Observable<DefaultResponse<PublicDataSource>> {
		return this.http
			.put<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source/${dataSourceSlug}`, dataSourceUpdateDto)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded') {
						this.dataSourceStore.upsert(res.data.id, res.data);
					}
				})
			);
	}

	public delete(dataSourceSlug: string): Observable<DefaultResponse<PublicDataSource>> {
		return this.http.delete<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source/${dataSourceSlug}`).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
					this.dataSourceStore.remove(dataSourceSlug);
				}
			})
		);
	}

	public getFromSource(reqBody: { targetId: string, accessKey: string, slug: string, type: string }): Observable<any> {
		return this.http.post<DefaultResponse<any>>(`${environment.apiUrl}/data-source/get-from-source`, reqBody).pipe(
			catchError(err => {
				return err;
			})
		);
	}

	public writeData(dataSourceSlug: string, dataSourceStoreWriteDto: DataSourceStoreWriteDto): Observable<DefaultResponse<PublicDataSource>> {
		return this.http.post<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source/${dataSourceSlug}/store`, dataSourceStoreWriteDto).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
				}
			})
		);
	}

	public importCsvData(dataSourceSlug: string, fileReq: FileDataImportUploadObject): Observable<DefaultResponse<PublicDataSource>> {
		const formData: FormData = new FormData();
		formData.append('file', fileReq.file);

		return this.http.post<DefaultResponse<PublicDataSource>>(`${environment.apiUrl}/data-source/${dataSourceSlug}/import/csv`, formData).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
				}
			})
		);
	}

	public deleteData(dataSourceSlug: string, dataSourceStoreDeleteDto: DataSourceStoreDeleteDto): Observable<DefaultResponse<undefined>> {
		return this.http.delete<DefaultResponse<undefined>>(`${environment.apiUrl}/data-source/${dataSourceSlug}/store`, {body: dataSourceStoreDeleteDto}).pipe(
			tap(res => {
				if (res.status === 'succeeded') {
				}
			})
		);
	}

	bulkGetFromUrl(id: string = '') {
		return this.http.get(`${environment.apiUrl}/data-source/bulk-export/${id}`, {
				responseType: 'json'
			})
			.pipe(
				catchError(err => {
					this.globalService.triggerErrorMessage(undefined, $localize `:Error Message@@reportExportJSONExportErrorMessageText:Error exporting JSON`);
					throw err;
				})
			);
	}
}
