import { Component, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { takeUntil } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-multi-add-input',
  templateUrl: './multi-add-input.component.html',
  styleUrls: ['./multi-add-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiAddInputComponent extends BaseInputComponent implements OnInit, OnChanges {
  @Input() addLabel: string = 'Add New Row';
  @Input() keyLabel: string = 'Key';
  @Input() keyProperty: string = 'key';
  @Input() valueLabel: string = 'Value';
  @Input() valueProperty: string = 'value';
  @Input() noDataMessage: string = 'No data';
  @Input() selection: any[] | null = null;
  @Input() showNoDataMessage: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() showRemoveButton: boolean = true;
  @Input() lockKey: boolean = false;
  @Input() keyOptions: { 'label': string, value: 'string' }[];
  @Input() dataType: 'array' | 'object' | 'delimited' = 'array';
  @Input() delimiter: string = ',';

  public skipChangeDetection: boolean = false;
  public changeSubscription: any;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onFormValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentValue && !changes.currentValue.firstChange) {
		// console.log('changes.currentValue', changes.currentValue?.currentValue, this.currentValue);
      if (this.skipChangeDetection) {
		// console.log('this.skipChangeDetection', this.skipChangeDetection);
        this.skipChangeDetection = false;
        return;
      }

      if (this.changeSubscription) {
        this.changeSubscription.unsubscribe();
      }

      this.initializeForm();
      this.onFormValueChanges();
    }
  }

  initializeForm(currentValue?: any, validators?: ValidatorFn | ValidatorFn[]) {
    if (!this.form) {
      this.form = this.fb.group({
        items: this.fb.array([])
      });
    }

    let value = currentValue || this.currentValue || this.parentForm?.get(this.controlName).value;

    if (value) {
      if (this.dataType === 'array' && Array.isArray(value)) {
        this.setArrayInput(value);
        if (!value.length) {
          this.addNewRow();
        }
      } else if (this.dataType === 'object' && typeof value === 'object' && value !== null) {
        this.setObjectInput(value);
        if (!Object.keys(value).length) {
          this.addNewRow();
        }
      } else if (this.dataType === 'delimited' && typeof value === 'string') {
        this.setDelimitedInput(value);
      }
    } else {
		this.clearForm(true);
		this.addNewRow();
	}
  }

  handleCurrentValueChange(value: any): void {
    this.initializeForm();
  }

  addNewRow(): void {
    const items = this.form.get('items') as FormArray;
    if (this.dataType === 'delimited') {
      items.push(this.fb.group({
        value: new FormControl('', Validators.required)
      }));
    } else {
      items.push(this.fb.group({
        key: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required)
      }));
    }
  }

  removeRow(index: number): void {
    const items = this.form.get('items') as FormArray;
    items.removeAt(index);
    this.emitDataChange(false);
  }

  setArrayInput(array: any[]): void {
    const items = this.form.get('items') as FormArray;
    items.clear();
    array.forEach(item => {
      items.push(this.fb.group({
        key: new FormControl(item[this.keyProperty], Validators.required),
        value: new FormControl(item[this.valueProperty], Validators.required)
      }));
    });
  }

  setObjectInput(object: any): void {
    const items = this.form.get('items') as FormArray;
    items.clear();
    Object.keys(object).forEach(key => {
      items.push(this.fb.group({
        key: new FormControl(key, Validators.required),
        value: new FormControl(object[key], Validators.required)
      }));
    });

    if (this.lockKey) {
      items.controls.forEach(control => {
        control.get('key').disable();
      });
    }
  }

  setDelimitedInput(value: string): void {
    const items = this.form.get('items') as FormArray;
    items.clear();
    value.split(this.delimiter).forEach(item => {
      items.push(this.fb.group({
        value: new FormControl(item.trim(), Validators.required)
      }));
    });
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  onFormValueChanges(): void {
    this.changeSubscription = this.form.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
      this.emitDataChange();
    });
  }

  emitValueChange(): void {
    // Do nothing.
  }

  emitDataChange(setSkipNextChange: boolean = true): void {
    let output;
    const itemsRawValue = this.items.getRawValue();

	  if (this.dataType === 'delimited') {
		  if (itemsRawValue.length === 0) {
			  setSkipNextChange = false;
		  }
	  }

    if (this.dataType === 'array') {
      output = itemsRawValue.map(item => ({ [this.keyProperty]: item.key, [this.valueProperty]: item.value }));
    } else if (this.dataType === 'object') {
      output = {};
      itemsRawValue.forEach(item => {
		output[item.key] = item.value;
      });
    } else if (this.dataType === 'delimited') {
      output = itemsRawValue.map(item => item.value).join(this.delimiter);
    }

    if (this.parentForm) {
      this.parentForm.get(this.controlName).setValue(output);
    }

	if (setSkipNextChange) {
		this.skipChangeDetection = true;
	}

	// console.log('emit: output', output);

    this.valueChange.emit(output);
  }

  clearForm(skipEmit: boolean = false): void {
	  const items = this.form.get('items') as FormArray;
	  items.clear({ emitEvent: !skipEmit });
  }

	updateFormSelection($event: any, i: number) {
		const items = this.form.get('items') as FormArray;
		items.at(i).get('value').setValue($event.value);
		this.emitDataChange();
	}
}
