import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { CustomizationItemDto } from './models/customization/customization.dto';
import { CreativeUnitDto } from './models/creative-unit/creative-unit.dto';
import { LayerDto } from './models/layer/layer.dto';
import { AnimationConfigDto, AnimationDto } from './models/animation.dto';
import { CategoryDto } from './models/category.dto';
import { AssetDto } from '../asset/models/asset.dto';
import { ThemeDto } from './models/theme.dto';
import { StylesConfigDto } from './models/styles.dto';
import { PrintConfigDto } from './models/print.dto';
import { AdvancedConfigDto } from './models/advanced-config.dto';
import { CreativeUnitListingSettings } from './models/creative-unit/creative-unit-listing.dto';
import { PublicUser, User } from "../user/user.entity";
import { LocaleDto } from './models/locale.dto';
import { ImageFormat, OutputConfig } from "../encode/models/job-def.dto";

export enum Status {
	Draft = 'draft',
	Published = 'published'
}

export enum SortStrategy {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum SortString {
	Name = 'name',
	Created = 'created'
}

export enum SummarySortString {
	Name = 'name',
	Modified = 'modified',
	CreatedByUserId = 'createdByUserId'
}

export enum ExportType {
	STATIC = 'static',
	VIDEO = 'video',
	HTML5 = 'html5',
	AUDIO = 'audio',
	PRINT = 'print'
}

export namespace ExportType {
	export function getExportTypeFileFormat(exportType: ExportType, outputConfig?: OutputConfig) {
		switch (exportType) {
			case ExportType.STATIC:
				if (outputConfig?.imageFormat === ImageFormat.PNG) {
					return 'PNG';
				}
				return 'JPG';
			case ExportType.VIDEO:
				return 'MP4';
			case ExportType.HTML5:
				return 'HTML5';
			case ExportType.AUDIO:
				return 'MP3';
			case ExportType.PRINT:
				return 'PDF';
			default:
				return;
		}
	}

	export function getExportTypeTitle(exportType: ExportType) {
		switch (exportType) {
			case ExportType.STATIC:
				return 'Static';
			case ExportType.VIDEO:
				return 'Video';
			case ExportType.HTML5:
				return 'HTML5';
			case ExportType.AUDIO:
				return 'Audio';
			case ExportType.PRINT:
				return 'Print';
			default:
				return;
		}
	}
}

export type PublicCreativeUnitPackage = Pick<
	CreativeUnitPackage,
	| 'id'
	| 'name'
	| 'description'
	| 'categories'
	| 'region'
	| 'status'
	| 'tags'
	| 'customizations'
	| 'creativeUnits'
	| 'layers'
	| 'containerClasses'
	| 'styles'
	| 'variables'
	| 'animation'
	| 'animationConfig'
	| 'printConfig'
	| 'thumbnail'
	| 'theme'
	| 'exportTypes'
	| 'creativeUnitListingSettings'
	| 'advancedConfig'
	| 'locale'
	| 'publishedAt'
	| 'modified'
>;

export type PublicCreativeUnitPackageSummary = Pick<CreativeUnitPackage, 'id' | 'name' | 'exportTypes' | 'publishedAt'> & {
	creativeUnitsNames: string[];
	visibleCreativeUnitsNames: string[];
};

export type CreativeUnitPackageSummary = Pick<CreativeUnitPackage, 'id' | 'name' | 'modified'> & {
	creativeUnitsNames: string[];
	description: string;
	createdByUser?: PublicUser | Partial<PublicUser>;
};

@Entity('creativePackage')
export class CreativeUnitPackage {
	constructor(value?: Partial<CreativeUnitPackage>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text', { nullable: true, default: 'New Package' })
	public name: string;

	@Column('text', { nullable: true })
	public description: string;

	@Column('jsonb', { nullable: true })
	public categories: { [key: CategoryDto['slug']]: CategoryDto };

	@Column('text', { nullable: true })
	public region: string;

	@Column({
		type: 'enum',
		enum: Status,
		default: Status.Draft,
		nullable: false
	})
	public status: Status;

	@Column('jsonb', { nullable: true })
	public tags: string[] | null;

	@Column('jsonb', { nullable: true })
	public customizations: CustomizationItemDto[] | null;

	@Column('jsonb', { nullable: true })
	public creativeUnits: CreativeUnitDto[] | null;

	@Column('jsonb', { nullable: true })
	public layers: LayerDto[] | null;

	@Column('text', { nullable: true })
	public containerClasses?: string;

	@Column('jsonb', { nullable: true })
	public styles: string | StylesConfigDto[] | null;

	@Column('jsonb', { nullable: true })
	public variables: object | null;

	@Column('jsonb', { nullable: true })
	public animation: AnimationDto | null;

	@Column('jsonb', { nullable: true })
	public animationConfig: AnimationConfigDto | null;

	@Column('jsonb', { nullable: true })
	public printConfig: PrintConfigDto | null;

	@Column('jsonb', { nullable: true })
	public thumbnail: AssetDto | null;

	@Column('jsonb', { nullable: true, default: [ExportType.STATIC] })
	public exportTypes: ExportType[] | null;

	@Column('jsonb', { nullable: true })
	public theme: ThemeDto | null;

	@Column('boolean', { default: false })
	public deleted: boolean;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public modified: string;

	@Column({ type: 'timestamptz', nullable: true })
	public publishedAt: string;

	@Column('text', { nullable: true })
	public priceProductId: string;

	@Column('jsonb', { nullable: true })
	creativeUnitListingSettings?: CreativeUnitListingSettings;

	@Column('jsonb', { nullable: true })
	public advancedConfig: AdvancedConfigDto | null;

	@Column('jsonb', { nullable: true })
	locale?: LocaleDto;

	@Column('uuid', { nullable: true })
	public createdByUserId?: string;
	@ManyToOne(() => User, {
		eager: false,
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'createdByUserId' })
	public createdByUser?: User | Partial<User>;

	public toPublic() {
		const pub: Partial<PublicCreativeUnitPackage> = {
			id: this.id,
			name: this.name,
			description: this.description,
			categories: this.categories,
			region: this.region,
			status: this.status,
			tags: this.tags,
			customizations: this.customizations,
			creativeUnits: this.creativeUnits,
			layers: this.layers,
			containerClasses: this.containerClasses,
			styles: this.styles,
			variables: this.variables,
			animation: this.animation,
			animationConfig: this.animationConfig,
			printConfig: this.printConfig,
			thumbnail: this.thumbnail,
			theme: this.theme,
			publishedAt: this.publishedAt,
			exportTypes: this.exportTypes,
			creativeUnitListingSettings: this.creativeUnitListingSettings,
			advancedConfig: this.advancedConfig,
			modified: this.modified,
			locale: this.locale
		};

		return pub as PublicCreativeUnitPackage;
	}

	public toPublicSummary() {
		const exportTypes = Array.from(
			new Set(
				this.creativeUnits
					.map(cu => cu.exportTypes)
					.flat()
					.filter(Boolean)
			)
		);
		let pub: Partial<PublicCreativeUnitPackageSummary> = {
			id: this.id,
			name: this.name,
			creativeUnitsNames: this.creativeUnits?.map(cu => cu.name) || [],
			visibleCreativeUnitsNames: this.creativeUnits?.filter(cu => !cu.hidden).map(cu => cu.name) || [],
			exportTypes: exportTypes.length ? exportTypes : this.exportTypes,
			publishedAt: this.publishedAt
		};

		return pub as PublicCreativeUnitPackageSummary;
	}

	public toSummary() {
		const sum: Partial<CreativeUnitPackageSummary> = {
			id: this.id,
			name: this.name,
			creativeUnitsNames: this.creativeUnits?.map(cu => cu.name) || [],
			description: this.description,
			modified: this.modified
		};

		if (this.createdByUser) {
			const publicUser = this.createdByUser.toPublic();

			sum.createdByUser = {
				id: publicUser.id,
				fullName: publicUser.fullName,
				email: publicUser.email
			};
		}

		return sum as CreativeUnitPackageSummary;
	}
}
