import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { PublicBusinessUser } from '../../../../../../api/src/business/business-user.entity';
import { PublicBusiness } from '../../../../../../api/src/business/business.entity';
import { BusinessNewSubscriptionRequestDto } from '../../../../../../api/src/business/models/business-new-subscription-request.dto';
import { BusinessSignUpRequestDto } from '../../../../../../api/src/business/models/business-signup-request.dto';
import { environment } from '../../../../environments/environment';
import { BaseEntityService } from '../base-entity.service';
import { QueryParams } from '../collection/collection.model';
import { GlobalQuery, GlobalService } from '../global';
import { DefaultResponse, FindResponse } from '../session';
import { BusinessStore } from './business.store';
import { SessionService } from '../session/session.service';
import { DataImportType } from '../../enums/data-import-type';
import { Observable, switchMap } from 'rxjs';
import { LocalizationService } from '../../services/localization.service';
import { validStripeLocales } from '../../models/locale';
import { BusinessListActiveQuery } from '../../../../../../api/src/business/models/business-list-active-query';
import { SortDirection } from '../../../../../../api/src/_core/utils/utils.paging';
import { PublicPaymentMethod } from '../../../../../../api/src/paymentmethod/paymentmethod.entity';

@Injectable({ providedIn: 'root' })
export class BusinessService extends BaseEntityService {
	router: any;
	constructor(
		private globalQuery: GlobalQuery,
		private businessStore: BusinessStore,
		private http: HttpClient,
		private readonly globalService: GlobalService,
		private readonly sessionService: SessionService,
		private readonly localizationService: LocalizationService
	) {
		super();
		// // debug
		// this.businessStore.add([
		// 	{ id: '1', name: 'Test Business 1 (Fake)', role: BusinessRole.Owner },
		// 	{ id: '2', name: 'Test Business 2 (Fake)', role: BusinessRole.Administrator },
		// 	{ id: '3', name: 'Test Business 3 (Fake)', role: BusinessRole.Creative },
		// 	{ id: '4', name: 'Test Business 4 (Fake)', role: BusinessRole.Finance }
		// ]);
	}

	public get({ page, limit, sortBy, query }: QueryParams) {
		this.businessStore.setLoading(true);

		const sortOption: any = {};
		if (sortBy) {
			sortOption.sortBy = sortBy.id;
			sortOption.sort = sortBy.direction;
		}

		if (query) {
			sortOption.query = query;
		}

		return this.http
			.get<DefaultResponse<PublicBusiness[]>>(`${environment.apiUrl}/business/list`, { params: { page, limit, ...sortOption } })
			.pipe(
				map(response => ({
					...response,
					data: response.data.map(business => this._prepareForUi(business, response['memberOf']))
				})),
				tap(response => {
					//this.businessStore.set(response.data);
					this.businessStore.setLoading(false);
				})
			);
	}

	public setActive(id: string) {
		//this.businessStore.setActive(id);
		this.getOne(id, true).subscribe();
	}

	public getOne(id: string, setActive: boolean = true, upsert: boolean = true) {
		return this.http.get<PublicBusiness>(`${environment.apiUrl}/business/${id}`).pipe(
			tap(business => {
				if (upsert) {
					this.businessStore.upsert(business.id, business);
				}

				if (setActive) {
					this.businessStore.setActive(id);
				}
			})
		);
	}

	public join(id: string) {
		this.businessStore.setLoading(true);

		return this.http
			.post<{ status: string; business: PublicBusiness; businessUser: PublicBusinessUser }>(
				`${environment.apiUrl}/business/${id}/join`,
				{}
			)
			.pipe(
				tap(({ business }) => {
					this.businessStore.upsert(business.id, business);
					this.sessionService.upsertBusinessToProfile(business);
				})
			);
	}

	public leave(id: string) {
		this.businessStore.setLoading(true);

		return this.http
			.post<{ status: string; business: PublicBusiness; businessUser: PublicBusinessUser }>(
				`${environment.apiUrl}/business/${id}/leave`,
				{}
			)
			.pipe(
				tap(({ status }) => {
					if (status === 'succeeded') {
						this.businessStore.remove(id);
						this.sessionService.removeBusinessFromProfile(id);
					}
				})
			);
	}

	public signUp(request: Partial<BusinessSignUpRequestDto>) {
		this.businessStore.setLoading(true);

		return this.http.post<any>(`${environment.apiUrl}/business/signup`, request).pipe(
			tap(response => {
				this.businessStore.upsert(response.business.id, response.business);
				this.businessStore.setActive(response.business.id);
			}),
			catchError(err => {
				this.handleBusinessError(err);
				throw err;
			}),
			finalize(() => this.businessStore.setLoading(false))
		);
	}

	public updateBusiness(id: PublicBusiness['id'], business: Partial<PublicBusiness>) {
		return this.http.put<PublicBusiness>(`${environment.apiUrl}/business/${id}`, business).pipe(
			tap(response => {
				console.error(response.users);
				this.businessStore.upsert(id, response);
			}),
			catchError(err => {
				this.handleBusinessError(err);
				throw err;
			})
		);
	}

	private handleBusinessError(err: any) {
		let defaultMessage = 'An error occurred while creating the business.';
		if (Object.keys(err?.error)?.length) {
			if (err.error.message) {
				err.error.message = err.error.message;
			} else {
				err.error.message =
					defaultMessage +
					' ' +
					Object.values(err.error)
						.map((value: any) => {
							if (typeof value === 'string') {
								return value[0].toUpperCase() + value.slice(1);
							}
							return null;
						})
						.filter((value: any) => value)
						.join(' ');
			}
		} else {
			err.error = { message: defaultMessage };
		}
		this.globalService.triggerErrorMessage(err);
	}

	public createSubscriptionSession(businessId: string, params: BusinessNewSubscriptionRequestDto) {
		return this.localizationService.localeCode$.pipe(
			take(1),
			switchMap((localeCode: string) => {
				const localeWithCorrectFormat = localeCode.slice(0, 2);
				const locale = validStripeLocales[localeWithCorrectFormat] ? localeWithCorrectFormat : undefined;
				params.locale = locale;
				return this.http.post<any>(`${environment.apiUrl}/business/${businessId}/subscribe`, params);
			})
		);
	}

	public validateSubscriptionSession(businessId: string, sessionId: string) {
		return this.http.post<any>(`${environment.apiUrl}/business/${businessId}/validate-subscription`, {
			sessionId
		});
	}

	public deleteBusinessUser(businessId: PublicBusiness['id'], businessUser: Partial<PublicBusinessUser>) {
		return this.http.delete<boolean>(`${environment.apiUrl}/business/${businessId}/user/${businessUser.businessUserId}`).pipe(
			tap(response => {
				this.businessStore.update(businessId, (business: PublicBusiness) => ({
					...business,
					users: business.users?.filter(user => user.id !== businessUser.id)
				}));
			})
		);
	}

	public updateBusinessUser(businessId: PublicBusiness['id'], businessUser: Partial<PublicBusinessUser>) {
		console.error(businessUser);
		return this.http
			.put<any>(`${environment.apiUrl}/business/${businessId}/user/${businessUser.businessUserId}`, {
				businessRole: businessUser.businessRole,
				isApprover: businessUser.isApprover,
				canPurchase: businessUser.canPurchase,
				adEditor: businessUser.adEditor
			})
			.pipe(
				tap(response => {
					this.businessStore.update(businessId, (business: PublicBusiness) => ({
						...business,
						users: business.users?.map(user => (user.id === response.id ? { ...user, ...response } : user))
					}));
				})
			);
	}

	public openBillingManagementPortalSession(businessId: PublicBusiness['id'], returnUrl: string) {
		return this.localizationService.localeCode$
			.pipe(
				take(1),
				switchMap((localeCode: string) => {
					const localeWithCorrectFormat = localeCode.slice(0, 2);
					const locale = validStripeLocales[localeWithCorrectFormat] ? localeWithCorrectFormat : undefined;
					return this.http.post<any>(`${environment.apiUrl}/business/${businessId}/billing-management-portal`, {
						returnUrl: returnUrl,
						locale
					});
				})
			)
			.toPromise();
	}

	public async openBillingPortalNewWindow(businessId: PublicBusiness['id'], returnUrl: string) {
		let portalObject = await this.openBillingManagementPortalSession(businessId, returnUrl);
		// if the business does not have a subscription redirect them to the subscription create page.
		if (portalObject?.status === 'no_subscription') {
			this.router.navigate(['/setup-subscription/' + portalObject.business.id], {
				replaceUrl: true,
				state: {
					previousState: window.location.pathname
				}
			});
		} else {
			window.open(portalObject.url, '_blank');
		}
	}

	public renewTrial(businessId: string) {
		return this.http
			.put<DefaultResponse<Partial<PublicBusiness>>>(`${environment.apiUrl}/business/${businessId}/renew-trial`, undefined)
			.pipe(
				map(response => {
					if (response?.status === 'succeeded') {
						this.businessStore.upsert(response.data.id, response.data);
						this.sessionService.upsertBusinessToProfile(response.data as PublicBusiness);
					}

					return response?.status === 'succeeded' ? response.data : undefined;
				}),
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					throw err;
				})
			);
	}

	public removeAll() {}

	private _prepareForUi(business: PublicBusiness, memberOf?: string[]) {
		if (!memberOf?.length) {
			return business;
		}

		return {
			...business,
			ui: {
				isMember: memberOf?.findIndex(businessId => businessId === business.id) > -1
			}
		};
	}

	public async getBusinessReport() {
		return this.http.get(`${environment.apiUrl}/reports/businesses`).toPromise();
	}

	public externalPaymentOnboarding(businessId: PublicBusiness['id'], body: any) {
		return this.http.post<any>(`${environment.apiUrl}/business/${businessId}/subscription/verify-external-payment-method`, body);
	}

	public async newExternalPaymentSubscription(businessId: PublicBusiness['id'], body: any) {
		console.log('newExternalPaymentSubscription', body, businessId);
		// this.businessStore.setLoading(true);

		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/subscription/new-external-payment-subscription`, body)
			.pipe(
				tap(response => {
					if (response?.status === 'success') {
						this.businessStore.upsert(businessId, response.data);
						this.sessionService.upsertBusinessToProfile(response.data as PublicBusiness);
					}
				})
			)
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
	}

	public listPaymentMethods(businessId: PublicBusiness['id']) {
		return this.http.get<any>(`${environment.apiUrl}/business/${businessId}/list-payment-methods`);
	}

	localizePaymentMethod(
		paymentMethod: PublicPaymentMethod,
		paymentMethodTranslations: { [key: string]: { label: string; ctaButtonLabel: string } }
	) {
		const translations = paymentMethodTranslations?.[paymentMethod?.slug];
		if (!translations) {
			return paymentMethod;
		}
		return {
			...paymentMethod,
			...translations
		};
	}

	public UpdatePaymentExternalId(businessId: PublicBusiness['id'], body: any) {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/subscription/update-external-payment-id`, body)
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
	}

	public listDataImportHistory({ page, limit, sortBy, query }: QueryParams) {
		// this.businessStore.setLoading(true);

		// const findReq: importFindDto = {
		// 	tile: search || undefined,
		// };

		const sortOption: any = {};
		if (sortBy) {
			sortOption.sortBy = sortBy.id;
			sortOption.sort = sortBy.direction;
		}

		return this.http
			.get<FindResponse<any>>(`${environment.apiUrl}/dataimport`, {
				params: { page, perPage: limit, ...sortOption, type: DataImportType.BusinessVerificationList }
			})
			.pipe(
				map(response => ({
					...response,
					data: response.results
				})),
				tap(response => {
					//this.businessStore.set(response.data);
					this.businessStore.setLoading(false);
				})
			);
	}

	public listUserImportHistory({ page, limit, sortBy, query }: QueryParams) {
		// this.businessStore.setLoading(true);
		// const findReq: importFindDto = {
		// 	tile: search || undefined,
		// };

		const sortOption: any = {};
		if (sortBy) {
			sortOption.sortBy = sortBy.id;
			sortOption.sort = sortBy.direction;
		}

		return this.http
			.get<FindResponse<any>>(`${environment.apiUrl}/dataimport`, {
				params: { page, perPage: limit, ...sortOption, type: DataImportType.Users }
			})
			.pipe(
				map(response => ({
					...response,
					data: response.results
				})),
				tap(response => {
					//this.businessStore.set(response.data);
					this.businessStore.setLoading(false);
				})
			);
	}

	public updatePaymentMethod(businessId: PublicBusiness['id'], body: any) {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/subscription/admin-update-payment-method`, body)
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
	}

	public userUpdatePaymentMethod(businessId: PublicBusiness['id'], paymentMethodId: string, paymentMethod: string) {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/subscription/user-update-payment-method`, {
				paymentMethodId: paymentMethodId,
				paymentMethod: paymentMethod
			})
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
	}

	public adminUpdateDefaultProducts(businessId: PublicBusiness['id'], body: any) {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/admin-update-default-products`, body)
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
	}

	public adminUpdatePaymentSettings(businessId: PublicBusiness['id'], body: any): Promise<DefaultResponse<PublicBusiness>> {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/admin-update-payment-settings`, body)
			.pipe(
				tap(response => {
					if (response.status === 'succeeded') {
						this.businessStore.upsert(businessId, response.data);
						this.sessionService.upsertBusinessToProfile(response.data);
					}
				})
			)
			.toPromise()
			.catch(err => {
				console.error(err);
				return null;
			});
	}

	public adminUpdateInternalBusinessSettings(businessId: PublicBusiness['id'], body: any): Promise<DefaultResponse<PublicBusiness>> {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/admin-update-internal-business-settings`, body)
			.pipe(
				tap(response => {
					if (response.status === 'succeeded') {
						this.businessStore.upsert(businessId, response.data);
						this.sessionService.upsertBusinessToProfile(response.data);
					}
				})
			)
			.toPromise()
			.catch(err => {
				console.error(err);
				return null;
			});
	}

	public adminUpdateTagSettings(businessId: PublicBusiness['id'], body: any): Promise<DefaultResponse<PublicBusiness>> {
		return this.http
			.post<any>(`${environment.apiUrl}/business/${businessId}/admin-update-business-tags`, body)
			.pipe(
				tap(response => {
					if (response.status === 'succeeded') {
						this.businessStore.upsert(businessId, response.data);
						this.sessionService.upsertBusinessToProfile(response.data);
					}
				})
			)
			.toPromise()
			.catch(err => {
				console.error(err);
				return null;
			});
	}

	public deleteBusiness(businessId: string): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/business/${businessId}`).pipe(
			tap(response => {
				this.businessStore.remove(businessId);
				this.sessionService.removeBusinessFromProfile(businessId);
			})
		);
	}

	findActiveBusinesses({ sortBy, page, limit }: QueryParams) {
		let businessQuery: Partial<BusinessListActiveQuery> = {
			perPage: limit,
			page: page || 1,
			sortBy: sortBy?.id,
			sort: sortBy?.direction as SortDirection
		};

		return this.http
			.get<FindResponse<PublicBusiness>>(`${environment.apiUrl}/business/list-active`, {
				params: { ...(businessQuery as BusinessListActiveQuery) }
			})
			.pipe(
				map(response => {
					return {
						totalResults: response.totalResults,
						data: response.results
					};
				})
			);
	}

	// public get({ page, limit, sortBy, query }: QueryParams) {
	// 	this.businessStore.setLoading(true);

	// 	const sortOption: any = {};
	// 	if (sortBy) {
	// 		sortOption.sortBy = sortBy.id;
	// 		sortOption.sort = sortBy.direction;
	// 	}

	// 	if (query) {
	// 		sortOption.query = query;
	// 	}

	// 	return this.http
	// 		.get<DefaultResponse<PublicBusiness[]>>(`${environment.apiUrl}/business/list`, { params: { page, limit, ...sortOption } })
	// 		.pipe(
	// 			map(response => ({
	// 				...response,
	// 				data: response.data.map(business => this._prepareForUi(business, response['memberOf']))
	// 			})),
	// 			tap(response => {
	// 				//this.businessStore.set(response.data);
	// 				this.businessStore.setLoading(false);
	// 			})
	// 		);
	// }
}
