<ng-container [formGroup]="form">
	<div class="show-locale">
		<mat-form-field [ngClass]="formFieldCss" class="pr-3" [class.full-width]="fullWidth" appearance="fill">
			<mat-label *ngIf="label && !italicPlaceholder">{{ label }}</mat-label>
			<mat-label *ngIf="!!italicPlaceholder"><i [ellipsisTooltip]="true">{{ italicPlaceholder }}</i></mat-label>
			<mat-select [formControlName]="controlName" [placeholder]="placeholder" [compareWith]="compareWithSomething" (openedChange)="openedChange.emit($event)">
				<!-- Key -->
				<ng-container *ngIf="optionValueKey">
					<mat-option *ngFor="let option of options" [value]="option[optionValueKey]">
						{{ getLocale(option.label || option.name || option.id || option, option.locale?.label ) }}
					</mat-option>
				</ng-container>

				<!-- No Key -->
				<ng-container *ngIf="!optionValueKey">
					<mat-option *ngFor="let option of options" [value]="option">
						{{ getLocale(option.label || option.name || option.id || option, option.locale?.label ) }}
					</mat-option>
				</ng-container>
			</mat-select>
		</mat-form-field>

		<app-set-locale-edit-button
				*ngIf="showLocale"
				[localeData]="localeData"
				[selection]="options"
				(localeChange)="localeChange.emit($event)"
		></app-set-locale-edit-button>
	</div>

	<div *ngIf="hint" class="input-hint text-pretty">{{ hint }}</div>
</ng-container>
