import { Entity, Column, PrimaryGeneratedColumn, Unique, ManyToOne, JoinColumn, OneToMany, JoinTable } from 'typeorm';

import { UserUtils, FormField } from './user.utils';
import { PublicSite, Site } from '../site/site.entity';
import { UserSettings, UserSettingsPrivate } from './models';
import { Business, PublicBusiness } from '../business/business.entity';
import { BusinessRole, BusinessUser } from '../business/business-user.entity';
import { AuthenticationStrategy, PublicAuthenticationStrategy } from '../authentication-strategy/authentication-strategy.entity';

export enum ActivationStatus {
	Pending = 'pending',
	Activated = 'activated'
}

export enum UserRole {
	SuperAdmin = 'super-admin',
	Admin = 'admin',
	User = 'user',
	Guest = 'guest'
}

export const UserRoleToString = {
	[UserRole.SuperAdmin]: 'Super Admin',
	[UserRole.Admin]: 'Admin',
	[UserRole.User]: 'User',
	[UserRole.Guest]: 'Guest'
};

export const UserRoleMap = Object.entries(UserRole).reduce((acc, cur, idx) => {
	acc[cur[1]] = idx;
	return acc;
}, {});

export type PublicUser = Pick<
	User,
	'id' | 'email' | 'siteId' | 'role' | 'reviewer' | 'created' | 'lastSeen' | 'settings' | 'acceptedTerms' | 'acceptedTermsDate'
> & {
	fullName?: string;
	site?: PublicSite;
	businesses?: PublicBusiness[];
	isApprover?: boolean;
	adEditor?: boolean;
	canPurchase?: boolean;
	businessRole?: BusinessRole;
	authenticationStrategyId: string;
	authenticationStrategy: PublicAuthenticationStrategy;
};
@Entity('users')
@Unique(['emailNormalized', 'site'])
export class User {
	constructor(value?: Partial<User>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text')
	public email: string;

	@Column('text')
	public emailNormalized: string;

	@Column('text')
	public siteId: string;
	@ManyToOne(
		type => Site,
		site => site.id,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'siteId' })
	public site: Site | Partial<Site>;

	@Column({
		type: 'enum',
		enum: UserRole,
		default: UserRole.Guest
	})
	public role: UserRole;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({
		type: 'enum',
		enum: ActivationStatus,
		default: ActivationStatus.Pending
	})
	public activationStatus: ActivationStatus;

	@Column({ type: 'boolean', nullable: false, default: false })
	public reviewer: boolean;

	@Column({ type: 'boolean', default: false })
	public deactivated: boolean;

	@Column('text', { nullable: true })
	public singlePass: string;

	@Column('timestamptz', { nullable: true })
	public singlePassExpire: string;

	@Column('text', { array: true, nullable: true })
	public authTokens: string[];

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public lastSeen: string;

	@Column('jsonb', { nullable: true, default: {} })
	public settings: UserSettings;

	@Column('text', {
		name: 'settingsPrivate',
		nullable: true
	})
	private _settingsPrivate: string = null;
	public get settingsPrivate(): UserSettingsPrivate {
		return UserUtils.decryptProfile(this._settingsPrivate, this.id);
	}
	public set settingsPrivate(value: UserSettingsPrivate) {
		this._settingsPrivate = UserUtils.encryptProfile(value, this.id);
	}

	@Column('boolean', { default: false })
	public acceptedTerms: boolean;

	@Column('timestamptz', { nullable: true })
	public acceptedTermsDate: string;

	@OneToMany(
		() => BusinessUser,
		businessUser => businessUser.user,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinTable({ name: 'businessUsers' })
	public userBusinesses?: BusinessUser[] | Partial<BusinessUser[]>;

	public businesses?: Business[];

	// These fields are applied if this user is queried as a business user.
	public isApprover?: boolean;
	public canPurchase?: boolean;
	public adEditor?: boolean;

	public businessRole?: BusinessUser['role'];
	@Column('text', { nullable: true })
	authenticationStrategyId: string = null;
	@ManyToOne(
		type => AuthenticationStrategy,
		authenticationStrategy => authenticationStrategy.id,
		{
			nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'authenticationStrategyId' })
	authenticationStrategy: AuthenticationStrategy | Partial<AuthenticationStrategy>;

	@Column('text', { nullable: true })
	authChallenge?: string;

	public toPublic(self: boolean = false) {
		let pub: Partial<PublicUser> = {
			id: this.id,
			fullName: this.settings?.fullName,
			email: this.email,
			siteId: this.siteId,
			role: this.role,
			reviewer: this.reviewer,
			isApprover: this.isApprover,
			adEditor: this.adEditor,
			canPurchase: this.canPurchase,
			created: this.created,
			lastSeen: this.lastSeen,
			authenticationStrategyId: this.authenticationStrategyId,
			acceptedTerms: this.acceptedTerms,
			acceptedTermsDate: this.acceptedTermsDate
		};

		if (this.authenticationStrategy) {
			pub.authenticationStrategy = new AuthenticationStrategy(this.authenticationStrategy).toPublic();
		}

		if (self) {
			pub.settings = this.settings;
		}

		if (this.isApprover) {
			pub.isApprover = this.isApprover;
		}

		if (this.adEditor) {
			pub.adEditor = this.adEditor;
		}

		if (this.businessRole) {
			pub.businessRole = this.businessRole;
		}

		if (this.userBusinesses?.length && !this.businesses?.length) {
			pub.businesses = this.userBusinesses.map(b => {
				let newHere = new BusinessUser(b).toBusiness().toPublic();
				return newHere;
			});
		} else if (this.businesses?.length) {
			pub.businesses = this.businesses.map(b => {
				let new2 = new Business(b).toPublic();
				return new2;
			});
		}

		return pub as PublicUser;
	}
}
