export function isAfter(date1, date2) {
	if (typeof date1 === 'string') {
		date1 = new Date(date1);
	}

	if (typeof date2 === 'string') {
		date2 = new Date(date2);
	}

	return new Date(date1).getTime() > new Date(date2).getTime();
}

export function toDateString(date: Date) {
	return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
		.getDate()
		.toString()
		.padStart(2, '0')}`;
}

export function todayString(): string {
	let today: Date = new Date();
	return `${monthNames[today.getMonth()]}${today.getDate()}${today.getFullYear()}`;
}


/**
 * Formats a given date into `MM-DD-YYYY` format.
 * - Accepts either a `Date` object or a date string.
 * - Throws an error if the input date is invalid.
 *
 * @param {Date | string} [input=new Date()] - The input date to format. Defaults to the current date.
 * @throws {Error} If the input date is invalid.
 * @returns {string} - The formatted date in `MM-DD-YYYY` format.
 */
export function formatDate(input: Date | string = new Date()): string {
	const date = typeof input === 'string' ? new Date(input) : input;

	if (isNaN(date.getTime())) {
		throw new Error('Invalid date format');
	}

	return [
		String(date.getMonth() + 1).padStart(2, '0'),
		String(date.getDate()).padStart(2, '0'),
		date.getFullYear()
	].join('-');
}

export const monthNames: string[] = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
]
